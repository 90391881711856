import React, {useContext} from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text } from "../../components/Core";
import PageWrapper from "../../components/PageWrapper";
import imgS1 from "../../assets/image/jpg/works/9-0.jpg";
import imgS2 from "../../assets/image/jpg/works/9-1.jpg";
import imgS3 from "../../assets/image/jpg/works/9-2.jpg";
import imgS4 from "../../assets/image/jpg/works/9-3.jpg";

import {Button} from "../../components/Core";
import GlobalContext from "../../context/GlobalContext";
import Slider from "react-slick";
import ImageZoom from 'react-image-zooom';

const WorkSingle = () => {
  const gContext = useContext(GlobalContext);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    focusOnSelect: false
  };
  return (
    <>
      <PageWrapper>
        <Container
                   css={`
            margin-top: 92px;
            padding-bottom: 0;
          `}>
          <Slider {...settings}>
            <div className={"p-1"}>
              <ImageZoom src={imgS1} className="w-100"/>
            </div>
            <div className={"p-1"}>
              <ImageZoom src={imgS2} className="w-100"/>
            </div>
            <div className={"p-1"}>
              <ImageZoom src={imgS3} className="w-100"/>
            </div>
            <div className={"p-1"}>
              <ImageZoom src={imgS4} className="w-100"/>
            </div>
          </Slider>
        </Container>
        <Section>
          <Container>
            <Row>
              <Col lg="8">
                <Title variant="secSm" className="my-4">
                  Зимний джемпер из пуха норки
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Мягкий и тёплый. Отличная альтернатива акриловым холодным свитерам.
                  <br/>
                  При должном уходе модель сохранит свой первоначальный вид много сезонов.
                  <br/>
                  Свяжу в любом размере/ цвете.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="3">
                <Text variant="tag">Пряжа</Text>
                <Title variant="cardBig" className="mt-3">
                  Китай
                </Title>
              </Col>
              <Col lg="5">
                <Text variant="tag">Состав</Text>
                <Title variant="cardBig" className="mt-3">
                  Пух норки 80%<br/> ПА 20%
                </Title>
              </Col>
              <Col lg="4">
                <Text variant="tag">Цена в размере 42*</Text>
                <Title variant="cardBig" className="mt-3">
                  230 BYN
                </Title>
              </Col>
            </Row>
            <Row>
              <Col lg="7" className={"d-flex align-items-center"}>
                <Text variant="small">* цена зависит от размера и стоимости пряжи на момент заказа</Text>
              </Col>
              <Col lg="5" className={"mt-2"}>
                <Button arrowRight onClick={(e) => {
                  e.preventDefault();
                  gContext.toggleOrder();
                }}>Оставить заявку</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
